<template>
  <PSection history-section>
    <div class="contents-wrapper">
      <section-title title="HISTORY" title-ko="종료된 공식 대회" black />
      <div class="contents-holder">
        <Slider :info="historyArenaData" :option="option" ref="slider" :navigation="pagination" slides-per-view="auto">
          <template v-slot:slide="{info, idx}">
            <div :class="['box', `${info.game}`]" @click="goArena(idx)">
              <div class="detail">
                <p v-html="info.subDetail"></p>
                <h3>{{ info.name }}</h3>
              </div>
              <div class="img-holder">
                <img :src="info.imgUrl" @click="goArena(idx)" />
                <button class="go-arena">대회 둘러보기</button>
              </div>
            </div>
          </template>
        </Slider>
      </div>
    </div>
  </PSection>
</template>

<script>
import Slider from '@shared/components/common/Slider.vue';
import gtag from '@shared/mixins/gtag';
import SectionTitle from '@/views/components/common/SectionTitle.vue';
import historyData from '@/data/endLeague/historyLeagueData';

export default {
  name: 'HistorySection',
  components: { SectionTitle, Slider },
  mixins: [gtag],
  data() {
    return {
      option: {
        slidesPerView: 'auto',
        breakpoints: {},
      },
    };
  },
  computed: {
    historyArenaData() {
      return historyData[this.$route.params.id] ?? [];
    },
    pagination() {
      return this.historyArenaData.length > 1;
    },
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    goArena(idx) {
      this.trackEvent('Main_end_arena_btn', 'click', `${this.upRouteGameId}`);
      window.open(this.historyArenaData[idx]?.arenaUrl);
    },
  },

};

</script>

<style lang="less">
@import '~@/less/proj.less';

[history-section] { .bgc(white); .wh(100%); .rel; .pt(52); .pb(80);
  .contents-wrapper { .max-w(1260); .mh-c; .rel;
    &::before { .cnt; .bgc(#f9f9fc); .wh(100%, 191); .abs; .lt(0, 46); .z(1);}
    [section-title] { .pl(20); }
    .contents-holder { .mh-c; .max-w(335); .w(100%); .mt(-20); .tc;
      [slider] {
        .swiper-navigation {
          > a {.br(50%); .ib; .wh(24); .bgc(rgba(0, 0, 0, .2)); .t(55%);
            &.swiper-button-prev { .l(0);
              &::after { .wh(15); .abs; .z(1); .lt(4, 50%); .t-y(-50%); .cnt; .contain('/img/pages/end/share/Arrow-B-left.svg'); .o(.4); }
            }
            &.swiper-button-next { .r(0);
              &::after { .wh(15); .abs; .z(1); .lt(4, 50%); .t-y(-50%); .cnt; .contain('/img/pages/end/share/Arrow-B-right.svg'); .o(.4); }
            }
          }
        }
      }
      .box { .pointer;
        .detail { .ib; .w(100%); .mt(73); .tl;
          > p { .fs(16, 20px); .c(black); .o(.7); .mb(8);}
          > h3 { .fs(24, 24px); .c(#4b38ff); .ls(-2%);}
        }
        .img-holder {
          img { .wh(335, 300); .z(-1); .pointer;}
          .go-arena { .wh(320, 52); .pr(20); .fs(16, 16px); .mt(24); .bgc(black); .c(white); .br(8); .rel;
            &:before { .cnt; .contain('/img/pages/end/share/Vector.svg'); .wh(16); .abs; .rt(95, 50%); .t-y(-50%);}
          }
        }
      }
    }
  }

  @media (@tl-up) {
    .p(52, 0, 100);
    .contents-wrapper {
      &::before {.wh(1440, 388); .t(80); }
      [section-title] { .pl(58); .mb(32);}
      .contents-holder { .max-w(none); .p(0, 57); .mt(0);
        [slider] {
          .swiper-container { .h(610);}
          .swiper-navigation {
            > a { .wh(52); .t(468);
              &:nth-of-type(1) { .l(-20);
                &::after { .wh(30); .abs; .z(1); .l(12); .cnt; .contain('/img/pages/end/share/Arrow-B-left.svg'); .o(.4); }
              }
              &:nth-of-type(2) { .r(-20);
                &:after { .wh(30, 30); .abs; .z(1); .l(12); .cnt; .contain('/img/pages/end/share/Arrow-B-right.svg'); .o(.4);}
              }
            }
          }
        }
        .box { .rel;
          .detail { .mt(75);
            > p { .fs(28, 28px); .mb(20);
              br { .hide;}
            }
            > h3 { .fs(52, 52px);}
          }
          .img-holder { .h(210); .rel;
            img { .abs; .wh(524, 470); .rt(0, -45);}
            .go-arena { .abs; .wh(320, 60); .fs(20, 20px); .lt(0, 30);
              &:before { .r(90);}
            }
          }
        }
      }
    }
  }

  @media (@ds-up) {
    .pt(124); .pb(120);
    .contents-wrapper { .p(0);
      &::before { .max-w(1310); .lt(50%, 120); .t-x(-50%); }

      [section-title] { .pl(0); .mb(-65); }
      .contents-holder { .p(0);
        [slider] {
          .swiper-container { .pt(122);}
          .swiper-navigation {
            > a { .wh(52); .t(300);
              &:nth-of-type(1) { .l(-50);}
              &:nth-of-type(2) { .r(-50);}
            }
          }
        }
        .box {
          .detail { .mt(76);}
          .img-holder {
            img { .wh(680, 610); .rt(0, -300);}
          }
        }

      }
    }
  }

}

</style>
