<template>
  <PSection special-league>
    <div class="contents-wrapper">
      <SectionTitle title="SPECIAL LEAGUE" title-ko="추천 대회" white />
      <div v-if="isArenaList" class="contents-holder">
        <Slider :info="arenaList" ref="slider" :autoplayDelay="sliderDuration" :mouse-event="false" :autoplay="!onlyOneList" :navigation="!onlyOneList" :pagination="!onlyOneList" :loop="!onlyOneList" slides-per-view="auto" @changed="changeIdx">
          <template v-slot:slide="{info}">
            <SpecialLeagueCard :info="info" />
          </template>
          <template v-slot:pagination="{progress,idx}">
            <div class="pagination-group">
              <div class="page">
                <span>{{ `${idx + 1}`.padStart(2, '0') }}</span>
                <em></em>
                <span>{{ `${arenaList.length}`.padStart(2, '0') }}</span>
              </div>
              <div class="fill" :style="{width: `${progress * 100}%`}"></div>
            </div>
          </template>
        </Slider>
        <div class="info-holder change" @click="goArena" ref="info">
          <span :class="['tag', {'open': isOfficialArena}]">{{ type[arenaList[activeIdx].tag] }}</span>
          <h4 class="name">{{ arenaList[activeIdx].title }}</h4>
          <ul>
            <li>
              <em>{{ startTime }} KST</em></li>
            <li>
              <em>{{ modeType || modeSize }}</em></li>
            <li>
              <em>{{ startDateFormat }}</em></li>
          </ul>
        </div>
      </div>
      <div v-else class="empty">
        <p>다음 추천 대회를 기대해주세요!</p>
        <em>COMING SOON</em>
      </div>
    </div>
  </PSection>
</template>

<script>
import Slider from '@shared/components/common/Slider.vue';
import { historyMove } from '@shared/utils/routerUtils';
import moment from 'moment-timezone';
import gtag from '@shared/mixins/gtag';
import SpecialLeagueCard from '@/views/components/end/SpecialLeagueCard.vue';
import SectionTitle from '@/views/components/common/SectionTitle.vue';

export default {
  name: 'SpecialLeague',
  mixins: [gtag],
  components: { SpecialLeagueCard, Slider, SectionTitle },
  data() {
    return {
      arenaList: null,
      activeIdx: 0,
      type: { SUPPOTER: '서포터즈 대회', SPECIAL: '레벨업 공식 대회' },
      sliderDuration: 5000,
      timeoutId: null,
    };
  },
  computed: {
    isArenaList() {
      return this.arenaList?.length > 0;
    },
    onlyOneList() {
      return this.arenaList?.length === 1;
    },
    isOfficialArena() {
      return this.arenaList[this.activeIdx].tag === 'SPECIAL';
    },
    modeSize() {
      const size = this.arenaList[this.activeIdx].management.mode;
      return `${size} : ${size}`;
    },
    modeType() {
      return this.arenaList[this.activeIdx].uniqueOption?.uniqueOptionAndValue?.MODE_TYPE;
    },
    startDateFormat() {
      return moment(this.arenaList[this.activeIdx].schedule.start).format('MM월 DD일');
    },
    startTime() {
      return moment(this.arenaList[this.activeIdx].schedule.start).format('HH:mm');
    },
  },
  methods: {
    changeIdx(idx) {
      this.activeIdx = idx;
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.$refs.info?.classList.remove('change');
      }
      setTimeout(() => {
        this.$refs.info?.classList.add('change');
      }, 10);

      this.timeoutId = setTimeout(() => {
        this.$refs.info?.classList.remove('change');
      }, this.sliderDuration);
    },
    goArena() {
      this.trackEvent('Main_Special_arena_go', 'click', `${this.upRouteGameId}`);
      historyMove(`${process.env.VUE_APP_HOME_URL}/arena/${this.arenaList[this.activeIdx].id}/info`);
    },
  },
  async asyncData({ services, route }) {
    const gameId = route.params.id === 'val' ? 'valorant' : route.params.id;
    const [specials, supporters] = await Promise.all([
      services.arenas.getSpecialRunningArenas(20, 0, gameId, 'PARTNER', process.env.VUE_APP_SUPPORTERS_SPECIAL_ID),
      services.arenas.getSupporterRunningArenas(20, 0, gameId, process.env.VUE_APP_SUPPORTERS_SPECIAL_ID),
    ]);

    return {
      arenaList: [...specials.items.map(a => ({ ...a, tag: 'SPECIAL' })), ...supporters.items.map(a => ({ ...a, tag: 'SUPPOTER' }))],
    };
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[special-league] { .bgc(#1a1d22); .wh(100%); .p(52, 0, 80);
  .contents-wrapper { .max-w(1260); .mh-c;
    [section-title] { .pl(20); .mb(24);}
    .contents-holder { .c(white); .mh-c; .max-w(335); .w(100%);
      [slider] { .rel;
        // .swiper-container { overflow-y: visible; overflow-x: clip; }
        .swiper-navigation { .abs; .rb(0, -182);
          > a {
            &:nth-of-type(1) { .l(-60);
              &:before { .cnt; .contain('/img/pages/end/share/Arrow-W-left.svg'); .wh(28);}
            }
            &:nth-of-type(2) { .r(0);
              &:before { .cnt; .contain('/img/pages/end/share/Arrow-W-right.svg'); .wh(28);}
            }
          }
        }
        .pagination { pointer-events: none; .calc-w(100%, 80); .b(-200);
          .page { .cnt; .abs; .lt(0, -10);
            span { .fs(14, 14px);}
            em { .ib; .wh(1, 14); .m(0, 10); .t-y(1); .bgc(white); .o(.3);}
          }
          .pagination-list { .wf; .pl(70);
            .pagination-item { .wf; .h(1); .br(20); .bgc(grey); .m(0, 6); .hide;
              &.active { .block;
                .fill { .h(1); .bgc(#fff);}
              }
            }
          }
        }
      }
      .info-holder { .w(100%); .pointer; .o(0); .min-h(161);
        &.change {
          .o(1); transition: opacity 1s;
        }
        .tag { .ib; .p(5, 8); .bgc(#dc6b16); .br(4); .tc; .fs(12, 14.4px); .mt(24); .mb(12);
          &.open { .bgc(#346144);}
        }
        .name { .fs(24, 32px); .ellipsis(2); word-break: break-all; }
        ul {.mt(18); .mb(45);
          li {.fs(16); .rel; .c(white); .mr(13); .ib; .pl(30); .o(.6);
            > em {.ib;}
            &:nth-of-type(1):before { .o(.6); .cnt; .contain('/img/pages/end/share/access_time.svg'); .wh(20); .abs; .lt(0, 0);}
            &:nth-of-type(2):before {.o(.6); .cnt; .contain('@{icon}/Arena_mode_w.svg'); .wh(20); .abs; .lt(0, 0);}
            &:nth-of-type(3):before {.o(.6); .cnt; .contain('@{icon}/Arena_date_w.svg'); .wh(20); .abs; .lt(0, 0);}
          }
        }
      }
    }
    .empty { .wh(100%, 211); .flex; flex-direction: column; justify-content: center; align-items: center; .bg('/img/pages/end/share/comingSoon.png'); .cover;
      > p { .block; .mb(12); .c(white); .fs(16, 16px); }
      > em { .block; .bold; .c(white); .fs(32, 32px); .ls(-4%);}
    }
  }

  @media (@tl-up) {
    .p(80, 58, 68);
    .contents-wrapper {
      [section-title] { .pl(0); }
      .contents-holder { .max-w(none);
        [slider] {
          .swiper-navigation { .lb(388, 18); .b(-222);}
          .pagination { .w(308); .b(-240);}
        }
        .info-holder { .mt(16); .min-h(0);
          .name { .fs(32, 42px);}
          ul { .mb(77);
            li { .mr(24);}
          }
        }
      }
      .empty { .h(280);
        > p { .mb(16); .fs(20, 20px);}
        > em { .fs(52, 52px);}
      }
    }
  }

  @media (@ds-up) {
    .h(508);
    .contents-wrapper {
      .contents-holder { .rel;
        [slider] {
          .swiper-container { .w(760); .mh(0); }
          .swiper-navigation { .rt(0, 282);}
          .pagination { .abs; .rt(85, 299); .calc-w(100%, 885); }
        }
        .info-holder { .abs; .lt(800, 0); .max-w(480); .h(200); .mt(0); }
      }
    }
  }
}

</style>
