import _assign from 'lodash/assign';

/**
 * @typedef {import('vue-router').Route} VueRoute
 * @typedef {import('vuex').Store} VueStore
 * @typedef {import('vue').Component} VueComponent
 * @typedef {({cursor: number, route?: VueRoute, store?: VueStore, services: ServiceProvider}) => Promise<CursorList>} FetchMap
 * */
/**
 * @param {{[k:string]: FetchMap}} fetchMaps
 */
export default fetchMaps => {
  const keys = Object.keys(fetchMaps);

  /**
   * @param {string} name
   * @param {VueRoute} route?
   * @param {VueStore} store?
   * @param {ServiceProvider} services
   * @param {any} defaultParams? - route와 store외에 참조할 데이터
   * @param {CursorList?} lastResult
   * @returns {Promise<*>}
   */
  const fetch = async (name, { route, store, services, defaultParams }, lastResult) => {
    if (lastResult && !lastResult.hasNext) return lastResult;
    const result = await fetchMaps[name]({ cursor: lastResult?.nextCursor ?? 0, route, store, services, ...defaultParams });
    if (lastResult) result.items = [...lastResult.items, ...result.items];
    result.name = name;
    return result;
  };

  /**
   * @param {VueRoute} route?
   * @param {VueStore} store?
   * @param {ServiceProvider} services
   * @param {any} defaultParams? - route와 store외에 참조할 데이터
   * @returns {Promise<*>}
   */
  const fetchAll = async ({ route, store, services, defaultParams } = {}) => {
    const fetched = await Promise.all(keys.map(key => fetch(key, { route, store, services, defaultParams })));
    let i = 0;
    const result = { __mixinFetcherDefaultParams__: defaultParams };
    keys.forEach(key => { result[key] = fetched[i++]; });
    return result;
  };

  return {
    methods: {
      /**
       * @description
       * 목록상에서 다음 페이지 자료를 가져올 때 사용
       * @param {FetchedData} fetchedResult - fetchAll로 가져온 this.$data 내부의 값
       * @this {VueComponent & CursorFetcherInstance}
       * */
      async fetchMore(fetchedResult) {
        const defaultParams = this._data.__mixinFetcherDefaultParams__;
        this[fetchedResult.name] = await fetch(fetchedResult.name, { route: this.$route, store: this.$store, services: this.$services, defaultParams }, this[fetchedResult.name]);
      },

      /**
       * @description
       * 상황이 변하여 새로이 목록을 받아와야하는경우 첫번째 커서로 다시 가져올 때 사용 e.g. 항목 삭제
       * @param {FetchedData|String} resetTarget - fetchAll로 가져온 this.$data 내부의 값 혹은 key
       * @this {VueComponent & CursorFetcherInstance}
       * */
      async resetFetch(resetTarget) {
        if (!resetTarget) return;
        const key = typeof resetTarget === 'string' ? resetTarget : resetTarget.name;
        const defaultParams = this._data.__mixinFetcherDefaultParams__;
        this[key] = await fetch(key, { route: this.$route, store: this.$store, services: this.$services, defaultParams });
      },

      /**
       * @description
       * 변경될 수 있는 상태값을 참조하여 목록을 가져오는 컴포넌트의 경우에 상태값을 URL에 노출하지 않고도 asyncData를 사용하고 싶다면,
       * 초기 상태값을 defaultParams 로 전달해 fetchAll 한 이후에 상태가 변경될때 이 메소드를 통해 상태값을 갱신하도록 함.
       * 이 메소드를 실행한 이후에 갱신할 목록에 대하여 resetFetch 메소드를 실행하여 목록을 갱신.
       * @param {Object} params 갱신할 defaultParams 값
       * @this {VueComponent & CursorFetcherInstance}
       * */
      updateDefaultParams(params) {
        if (this._data.__mixinFetcherDefaultParams__) {
          _assign(this._data.__mixinFetcherDefaultParams__, params);
        } else {
          this._data.__mixinFetcherDefaultParams__ = params;
        }
      },
      async fetchInitAll(defaultParams = null) {
        const data = await fetchAll({ route: this.$route, store: this.$store, services: this.$services, defaultParams });
        _assign(this.$data, data);
      },
      fetchInit(key, defaultParams = null) {
        return fetch(key, { route: this.$route, store: this.$store, services: this.$services, defaultParams });
      },
    },
    fetch: fetchAll,
  };
};
