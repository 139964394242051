<template>
  <div special-league-card>
    <div class="image-holder" @click="goArena">
      <img class="img" :src="customImg" v-if="info.posterType === 'CUSTOM_IMAGE'" />
      <img class="img" :src="sampleImg" v-else />
    </div>

  </div>
</template>

<script>
import gtag from '@shared/mixins/gtag';
import { historyMove } from '@shared/utils/routerUtils';
import { cdnSrc } from '@shared/utils/cdnUtils';
import { ARENA_SAMPLE_POSTER } from '@/constants/sample';

export default {
  name: 'SpecialLeagueCard',
  mixins: [gtag],
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    sampleImg() {
      return ARENA_SAMPLE_POSTER[this.info.posterType];
    },
    customImg() {
      return cdnSrc(this.info.images.lg);
    },
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    goArena() {
      this.trackEvent('Main_Special_arena_go', 'click', `${this.upRouteGameId}`);
      historyMove(`${process.env.VUE_APP_HOME_URL}/arena/${this.info.id}/info`);
    },
  },

};

</script>

<style lang="less">
@import '~@/less/proj.less';

[special-league-card] {
  .image-holder { .pointer; .wh(335, 200); .br(12); .crop;
    .img {.h(100%); .block; object-fit: cover; object-position: top left; }
  }

  @media (@tl-up) {
    .image-holder { .wh(760, 428);
      .img {}
    }

  }

  @media (@ds-up) {
    .flex;
    justify-content: center;
    align-items: flex-start;

  }
}

</style>
