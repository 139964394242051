<template>
  <div arena-card v-if="info" :class="{ sm: smSize, lg: lgSize, list, 'status-on':statusOn}" @click="goArena(info)">
    <ArenaCardThumb :list="list" :sm="smSize" :lg="lgSize" :info="info">
    </ArenaCardThumb>
    <ArenaCardTitle :list="list" :sm="smSize" :lg="lgSize" :info="info" :status-on="statusOn" :extra-profile="extraProfile" />
    <article class="status-holder" v-if="statusOn">
      <label>
        <arena-card-timer v-slot="{ joinState, remainsToNextState }" :info="info">
          <slot>
            <em>{{ $t(['_.card.title', joinState]) }}</em>{{ remainsToNextState }}
          </slot>
        </arena-card-timer>
      </label>
      <p :class="statusMap.status"><em>{{ $t(`_.card.participateProgress.${statusMap.status}`) }}</em>
        <span>
          <img :src="statusMap.icon" alt="status-icon" />
        </span>
      </p>
    </article>
  </div>
</template>

<script>
import { historyMove } from '@shared/utils/routerUtils';
import Specific from '@shared/types/Specific';
import { getter } from '@shared/utils/storeUtils';
import gtag from '@shared/mixins/gtag';
import ArenaCardTimer from '@/views/components/end/card/ArenaCardTimer.vue';
import ArenaCardTitle from '@/views/components/end/card/ArenaCardTitle.vue';
import ArenaCardThumb from '@/views/components/end/card/ArenaCardThumb.vue';

export default {
  name: 'ArenaCard',
  lexicon: 'arena',
  components: { ArenaCardTitle, ArenaCardThumb, ArenaCardTimer },
  mixins: [gtag],
  props: {
    profile: { type: Boolean, default: true },
    extraProfile: Boolean,
    statusOn: Boolean,
    list: Boolean,
    sm: Boolean,
    lg: Boolean,
    infoOnly: Boolean,
    hideLike: Boolean,

    /** @type {ArenaInfo} */
    info: Specific,
  },
  data() {
    return {
      size: null,
      statusInfo: null,
      like: false,
    };
  },
  computed: {
    isLogin: getter('auth', 'isLogin'),
    infoSmSize() {
      return this.sm || this.matchedMediaDevice === 'M';
    },
    lgSize() {
      return !this.list && this.lg;
    },
    smSize() {
      return !this.list && (this.sm || this.matchedMediaDevice === 'M' || this.matchedMediaDevice === 'P');
    },
    isEnd() {
      return this.info.state === 'END';
    },
    participantProgress() {
      return this.statusInfo?.arenaApply?.progress;
    },
    /** @type {boolean} */
    isAvailableCheckIn() {
      return this.info?.isAvailableCheckIn ?? false;
    },
    /** @type {number} */
    checkinDatetime() {
      return this.statusInfo?.arenaApply?.checkinDatetime;
    },
    statusMap() {
      switch (true) {
        case this.isEnd:
          return { status: '', icon: '' };
        case this.participantProgress !== 'COMPLETE':
          return { status: 'checkin', icon: '/img/icon/arena/ico_tooltip_error.svg' };
        case this.participantProgress === 'COMPLETE' && !this.isAvailableCheckIn:
          return { status: 'success', icon: '/img/icon/arena/ico_tooltip_success.svg' };
        case this.participantProgress === 'COMPLETE' && this.isAvailableCheckIn && !this.checkinDatetime:
          return { status: 'lack', icon: '/img/icon/arena/ico_tooltip_checkin.svg' };
        case this.participantProgress === 'COMPLETE' && this.isAvailableCheckIn && this.checkinDatetime:
          return { status: 'success', icon: '/img/icon/arena/ico_tooltip_success.svg' };
        default:
          return { status: '', icon: '' };
      }
    },
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    async likeToggle() {
      if (!this.isLogin) {
        this.$services.auth.oAuthLogin(true);
        return;
      }

      if (this.like) await this.$services.arenas.arenaLikeOff(this.info.id);
      else await this.$services.arenas.arenaLikeOn(this.info.id);
      this.like = !this.like;
    },
    goArena(info) {
      this.trackEvent('Main_Community_arena_go', 'click', `${this.upRouteGameId}`);
      historyMove(`${process.env.VUE_APP_HOME_URL}/arena/${info.id}/info`);
    },
  },
  async mounted() {
    if (this.statusOn) this.statusInfo = await this.$services.arenas.getApplyStatus(this.info.id);
    if (!this.hideLike) this.like = this.info.isLiked;
  },
  beforeDestroy() {
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[arena-card] {.w(100%); .mh-c; .rel; .block; .pointer; .mb(16);
  .option { .abs; .rt(12, 18); .z(2);
    .btn-like { .wh(40); .abs; .rt(0, 0); .bgc(white); .br(12); .z(1);
      &:after { .cnt; .abs; .lt(50%, 50%); .t-xyc; .contain('@{icon}/ico_like_default.svg'); .wh(32); .o(.5);}
      &.on:after { .contain('@{icon}/ico_like_active.svg'); .o(1);}
    }
  }
  &.list { .br(20); .crop; .bgc(white); .max-h(115);}
  &.lg {}
  &.sm { .br(16); .crop; }
  &.status-on { .h(140); .max-h(140); .mb(56) !important; overflow: visible;
    .btn-like { .hide !important;}
    .status-holder { .abs; .lb(0, 10); .z(5); .w(100%); .-box; .pl(115);
      label { .ib; .c(#2c81ff); .bold; .vam; .fs(14, 24px);
        em { .p(0, 8); .br(20); .-a(#2c81ff, 2px); .ib; .vam; .-box; .mr(4); .lh(20); .bgc(rgba(0, 0, 0, 0.04)); }
      }
      > p {.abs; .rt(12, 42); .w(100%); .tr;
        > em { .max-w(calc(100% - 50px)); .ib; .ellipsis; .vam;}
        > span { .ib; .wh(32); .br(10); .vam; .ml(6); .rel;
          img { .wh(24); .abs; .lt(50%, 50%); .t-xyc; .o(.4);}
        }
        &.success { .c(#12d5a3);
          > span {background-image: linear-gradient(135deg, rgb(4, 215, 177), rgb(118, 231, 235) 100%);}
        }
        &.lack { .c(#df3a3a);
          > span {background-image: linear-gradient(135deg, rgb(223, 58, 58), rgb(255, 144, 144));}
        }
        &.checkin { .c(#ffb466);
          > span {background-image: linear-gradient(135deg, rgb(255, 199, 14), rgb(255, 177, 117) 100%);}
        }
      }
    }
    @media (@tl-up) {
      .status-holder { .pl(220); }
      [arena-card-title] .title-holder { .pr(150);}
    }
    @media (@tl-up) {
      .max-w(446) !important;
      .max-h(115); .mb(16) !important;
      [arena-card-title] .title-holder { .pr(230);}
      .status-holder { .pl(0); width: auto; left: auto; bottom: auto; .rt(20, 50%); .t-yc;
        > p { .static; .ib; .fs(0); .vam; .ml(10); width: auto;}
      }
    }
  }
}
</style>
