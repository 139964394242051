<template>
  <article arena-card-thumb :class="{list,sm,lg,'end':isEnd}">
    <CdnImg :src="customImg" v-if="info.posterType === 'CUSTOM_IMAGE'" />
    <img :src="sampleImg" v-else alt="lvup-card-thumb" />
    <div class="option-holder">
      <slot />
    </div>
  </article>
</template>

<script>
import Specific from '@shared/types/Specific';
import CdnImg from '@shared/components/common/CdnImg.vue';
import { ARENA_SAMPLE_POSTER } from '@/constants/sample';

export default {
  name: 'ArenaCardThumb',
  components: { CdnImg },
  props: {
    list: Boolean,
    sm: Boolean,
    lg: Boolean,
    info: Specific,
  },
  computed: {
    isEnd() {
      return this.info.state === 'END';
    },
    sampleImg() {
      return ARENA_SAMPLE_POSTER[this.info.posterType];
    },
    customImg() {
      return this.info.images.lg;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[arena-card-thumb] { .bgc(#a4a3ae); .rel; .crop;
  &.sm { .wh(100%, 190); .max-w(335);
    &.end {
      > img { filter: grayscale(1); }
    }
    > img { .wh(100%); .abs; .z(1); object-fit: cover;}
    .option-holder {.wh(100%); .rel; .z(2); }

    &.list {.wh(100, 100%); .abs; .lt(0, 0); .br-l(20);
      @media (@tp-up) {
        .w(204);

      }
    }
  }
  &.lg { .wh(100%, 233); .br(16);
    @media (@tl-up) {
      .h(272);
      &.sm { .wh(100%, 233); .bgc(red); }
    }
    @media (@ds-up) {
      .h(478);

    }
  }
  @media (@tl-up) {
    &.sm { .wh(100%, 233); .min-w(416); .max-w(none) !important;}
  }
}
</style>
