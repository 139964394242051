<template>
  <PSection community-league v-if="arenaCursorList.items.length > 0">
    <div class="contents-wrapper">
      <SectionTitle title="COMMUNITY LEAGUE" title-ko="일반 대회" black />
      <div class="contents-holder">
        <ArenaCard v-for="item in arenaCursorList.items" :key="item.id" :info="item" hide-like sm />
      </div>
      <button v-if="hasNext" @click="showMore">더보기</button>
    </div>
  </PSection>
</template>

<script>
import cursorFetcher from '@shared/mixins/cursorFetcher';
import SectionTitle from '@/views/components/common/SectionTitle.vue';
import ArenaCard from '@/views/components/end/card/ArenaCard.vue';

export default {
  name: 'CommunityLeague',
  components: { SectionTitle, ArenaCard },
  data() {
    return {
      arenaCursorList: { items: [] },
      cardCount: {
        'M': 2,
        'T': 2,
        'D': 3,
      },
    };
  },
  mixins: [
    cursorFetcher({
      arenaCursorList: ({ cursor, services, size, gameId, lvupSpecialId }) => services.arenas.getCommunityRunningArenas(size, cursor, gameId, lvupSpecialId),
    }),
  ],
  computed: {
    matchedCount() {
      return this.cardCount[this.matchedMediaDevice];
    },
    gameId() {
      return this.$route.params.id === 'val' ? 'valorant' : this.$route.params.id;
    },
    hasNext() {
      return this.arenaCursorList?.hasNext ?? false;
    },
  },
  methods: {
    reset() {
      this.updateDefaultParams({ size: this.matchedCount, gameId: this.gameId, lvupSpecialId: process.env.VUE_APP_SUPPORTERS_SPECIAL_ID });
      this.resetFetch('arenaCursorList');
    },
    showMore() {
      this.fetchMore(this.arenaCursorList);
    },

  },
  watch: {
    matchedCount: 'reset',
  },
  mounted() {
    this.reset();
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';
[community-league] { .bgc(#ebebf0); .wh(100%); .pt(52); .pb(48);
  .contents-wrapper { .max-w(1260); .mh-c;
    [section-title] { .pl(20); .mb(24);}
    .contents-holder { .max-w(335); .mh-c;
    }
    button { .block; .wh(85, 40); .mh-c; .bgc(white); .br(33); .fs(16, 16px); .c(#394145); .mt(8);}
  }
  @media (@tl-up) {
    .p(60, 58);
    .contents-wrapper {
      [section-title] { .mb(32); .pl(0);}
      .contents-holder { .ib-list; .max-w(none);
        [arena-card] { width: calc(50% - 8px); .mr(16); .mb(32);
          &:nth-of-type(even) { .mr(0); }
        }
      }
    }
  }
  @media (@ds-up) {
    .pt(160);
    .contents-wrapper {
      .contents-holder {
        [arena-card] { width: calc((100% - 32px) / 3); .mr(16) !important;
          &:nth-of-type(3n) { .mr(0) !important; }
        }
      }
    }
  }

}

</style>
