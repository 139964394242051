<template>
  <div arena-card-title v-if="info" :class="[{list, sm, lg,'extra':extraProfile && isDesktopSize}]">
    <article>
      <p class="m">{{ $date(arenaInfo.schedule.start, 'month') }}</p>
      <p class="d">{{ $date(arenaInfo.schedule.start, 'day') }}</p>
    </article>
    <div class="title-holder">
      <h3>{{ arenaInfo.title }}</h3>
      <p>{{ $date(arenaInfo.schedule.start, 'time') }} {{ arenaInfo.gameId }} • {{ modeType }}</p>
    </div>
    <div class="limit-holder" v-if="statusOn">
      <span>{{ $t('_.pre') }}</span>
      <em>{{ remain }}</em>
      <p class="marker">
        <span>{{ $t('_.complete') }}</span>
        <em /></p>
    </div>
    <div class="extra-profile" v-if="extraProfile && isDesktopSize && !list">
      <Avatar class="avatar" dimension="40x40" :info="avatarInfo" />
      <p class="name">{{ info.specialTagInfo.name || $t('arena.lvupOfficial') }}</p>
    </div>
  </div>
</template>

<script>
import { remainHms } from '@shared/utils/timeUtils';
import Avatar from '@shared/components/common/Avatar.vue';

export default {
  name: 'ArenaCardTitle',
  lexicon: 'arena.card.title',
  components: { Avatar },
  props: {
    info: Object,
    sm: Boolean,
    lg: Boolean,
    list: Boolean,
    statusOn: Boolean,
    extraProfile: Boolean,
    bgFill: { type: Boolean, default: true },
  },
  computed: {
    /** @type {ArenaInfo} */
    arenaInfo() {
      // noinspection JSValidateTypes
      return this.info;
    },
    modeType() {
      return this.arenaInfo?.uniqueOption?.uniqueOptionAndValue?.MODE_TYPE || `${this.arenaInfo.management.mode} v ${this.arenaInfo.management.mode}`;
    },
    remain() {
      return remainHms(this.$store, this.arenaInfo.schedule.end);
    },
    avatarInfo() {
      const { id, specialTagInfo } = this.info;
      if (specialTagInfo.name === '') specialTagInfo.thumbnailUrl = `${process.env.VUE_APP_HOME_URL}/img/defaults/LVUP-logo-red.svg`;
      return { id, ...specialTagInfo };
    },
    isDesktopSize() {
      return this.matchedMediaDevice === 'D';
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[arena-card-title] { .rel; .-box;
  > article { .flex-middle; .c(#191919); .bgc(#ebebf0); .wh(52, 75); .br(8); .tc;
    > .m { .fs(14); .bold; }
    > .d { .fs(26, 26px); .bold; .mt(6);}
  }
  h3 { .c(#191919); }
  .limit-holder {.hide;}
  .title-holder {
    h3 { .c(#191919);
      & + p { .c(#a4a3ae); .ellipsis;}
    }
  }
  &.list { .p(20, 20, 20, 116);
    > article {.lt(116, 20); .abs;}
    .title-holder { .flex-middle; .min-h(75); .p(0, 60, 0, 65);
      > h3 { .fs(15, 20px); .ellipsis(3); .mb(4); }
      > p { .fs(13); .c(#a4a3ae); .bold;}
    }
    .limit-holder {.mt(16); .c(#2c81ff); .extra-bold; .ib-list; .fs(14); .hide;
      > span { .bgc(rgba(0, 0, 0, 0.04)); .-a(#2c81ff, 2px); .br(20); .p(0, 6); .vam; .mr(6); }
      > em { .vam; }
      > p { .ib-list; .abs; .rb(20, 20);
        > span { .vam; .c(#12d5a3); .fs(14); .mr(6);}
        > em { .vam; .wh(32); background-image: linear-gradient(135deg, rgb(4, 215, 177), rgb(118, 231, 235) 100%); .br(8);}
      }
    }
    &.limit .limit-holder { .block; }
    @media (@tl-up) {
      .pl(220);
      > article { .l(220); }
      .title-holder {
        > h3 { .fs(20, 24px); .ellipsis(2); .mb(10); }
        > p { .fs(14); }
      }
      .limit-holder { .rt(20, 50%); .t-yc; .abs; .mt(0); }
      &.limit {
        .title-holder { .pr(240); }
        .limit-holder {
          > p {.static; .vam; .ml(16);
            > span {.hide;}
          }
        }
      }
    }
  }

  &.sm {.bgc(#fff); .p(16, 16, 16, 82); .h(106);
    > article { .bgc(#ebebf0); .abs; .lt(16, 16);}
    .title-holder { .flex-middle; .min-h(75);
      > h3 { .ellipsis(2); .fs(20); .mb(8); }
      > p { .fs(12); }
    }
    &.lg { .pl(66);
      > article { .l(0);}
    }
    &.extra { .h(164); .pb(74);
      .extra-profile { .wh(100%, 58); .abs; .-box; .lb(0, 0); .p(9, 16); .ib-list;
        &:before { .cnt; .calc-w(100%, 32); .h(1); .bgc(rgba(164, 163, 174, .3)); .abs; .lt(50%, 0); .t-xc; }
        [avatar] { .vam; .wh(40); .mr(6);}
        .name { .fs(14); .c(#a4a3ae); .vat; .pt(10);}
      }
    }
  }
  @media (@tp-up) {
    &.lg { .p(17, 0); .rel;
      > article { .wh(74, 104); .bgc(white); .abs; .lt(0, 17);
        > .m { .fs(20); }
        > .d { .fs(36); }
      }
      .title-holder { .p(0, 20, 0, 88); .min-h(104); .-box; .flex-middle;
        > h3 { .fs(24); .ellipsis(2); }
        > p { .fs(14); }
      }
    }
    &.sm {
      .title-holder {
        > h3 { .mb(6);}
      }
    }
  }
  @media (@ds-up) {
    &.lg {
      .title-holder {
        > h3 { .fs(36, 38px); .mb(5);}
        > p { .fs(20); }
      }
    }
  }
}

</style>
