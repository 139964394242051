export default {
  lol: {
    main: '<em>5시 방향에</em> 인생 롤 친구 출현!',
    sub: '매 월 열리는 롤 이벤트 대회, 게임 친구 찾기까지!',
    discordUrl: 'https://discord.gg/PkpEJxM98K',
    imgUrl: '/img/pages/end/LOL/Discord_image.png',
  },
  wr: {
    main: '<em>와리 같이 할 사람</em>이 왜 없어!',
    sub: '매 월 열리는 와일드 리프트 이벤트 대회, 게임 친구 찾기까지!',
    discordUrl: 'https://discord.gg/PkpEJxM98K',
    imgUrl: '/img/pages/end/WR/Discord_image.png',
  },
  val: {
    main: '<em>발로란트 같이 하러</em> 가보자고!',
    sub: '매 월 열리는 발로란트 이벤트 대회, 게임 친구 찾기까지!',
    discordUrl: 'https://discord.gg/PkpEJxM98K',
    imgUrl: '/img/pages/end/VAL/Discord_image.png',
  },
  tft: {
    main: '랜덤 깐부 말고, <em>찐 깐부랑 놀자!!</em>',
    sub: '매 월 열리는 TFT 이벤트 대회, 게임 친구 찾기까지!',
    discordUrl: 'https://discord.gg/PkpEJxM98K',
    imgUrl: '/img/pages/end/TFT/Discord_image.png',
  },

};
