export const ARENA_SAMPLE_BG = {
  SAMPLE_IMAGE1: '/img/sample/game-bg-01.jpg',
  SAMPLE_IMAGE2: '/img/sample/game-bg-02.jpg',
};

export const ARENA_SAMPLE_POSTER = {
  SAMPLE_IMAGE1: '/img/sample/Poster_Default1.jpg',
  SAMPLE_IMAGE2: '/img/sample/Poster_Default2.jpg',
  FALLBACK: '/img/sample/Poster_fallback.jpg',
};

export const ARENA_SAMPLE_THUMB = {
  SAMPLE_IMAGE1: '/img/sample/GameBG-01.jpg',
  SAMPLE_IMAGE2: '/img/sample/GameBG-01.jpg',
};

export const TEAM_SAMPLE_BACKGROUND = [
  '/img/sample/team_sum_bg1.jpg',
  '/img/sample/team_sum_bg2.jpg',
  '/img/sample/team_sum_bg3.jpg',
  '/img/sample/team_sum_bg4.jpg',
  '/img/sample/team_sum_bg5.jpg',
  '/img/sample/team_sum_bg6.jpg',
  '/img/sample/team_sum_bg7.jpg',
  '/img/sample/team_sum_bg8.jpg',
  '/img/sample/team_sum_bg9.jpg',
  '/img/sample/team_sum_bg10.jpg',
];
