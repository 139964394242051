<template>
  <PSection discord-section :class="`${gameId}`">
    <div class="contents-wrapper">
      <div class="detail">
        <img :src="discordComment.imgUrl" />
        <div class="contents" :class="{'narrow-image': isVal}">
          <p class="main" v-html="discordComment.main"></p>
          <p class="sub">{{ discordComment.sub }}</p>
        </div>
      </div>
      <div class="discord">
        <a :href="discordComment.discordUrl" target="_blank" @click="goDiscord">
          <img src="/img/pages/end/share/discordIcon.svg" alt="discord-icon" />
          디스코드 바로가기 </a>
      </div>
    </div>
  </PSection>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import commentData from '@/data/endLeague/discordCommentData';

export default {
  name: 'DiscordSection',
  mixins: [gtag],
  computed: {
    discordComment() {
      return commentData[this.gameId];
    },
    gameId() {
      return this.$route.params.id;
    },
    upRouteGameId() {
      return this.gameId.toUpperCase();
    },
    isVal() {
      return this.$route.params.id === 'val';
    },
  },
  methods: {
    goDiscord() {
      this.trackEvent('Main_discord_btn', 'click', `${this.upRouteGameId}`);
    },
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[discord-section] { .bgc(white); .wh(100%, 182); .flex; align-items: center; justify-content: center; .ph(20);
  .contents-wrapper { .flex; flex-direction: column; justify-content: space-between; align-items: center; .crop; .max-w(1260); .mh-c;
    .detail { .mb(20);
      img { .hide;}
      .main { .fs(24, 24px); .ls(-2%); .bold; .mb(8);
        > em { .c(#5b91f5);}
      }
      .sub { .fs(14, 14px);
      }
    }
    .discord {
      > a { .flex-center; .wh(265, 40); .bgc(#5b91f5); .br(40); .fs(16, 16px); .c(white);
        > img { .w(24); .mr(8); }
      }
    }
  }

  @media (@tl-up) {
    .block; .pt(54); .ph(52);
    .contents-wrapper { flex-direction: row; justify-content: space-between;
      .detail { .mb(0);
        .main { .fs(40, 40px);}
        .sub { .fs(22, 22px);}
      }
      .discord {
        > a { .wh(282, 60); .fs(22, 22px);
          > img { .w(36); }
        }
      }
    }
  }

  @media (@ds-up) {
    .pt(0);
    .contents-wrapper { .ph(0);
      .detail { .rel; .flex; justify-content: flex-start; align-items: center; .h(182);
        img { .abs; .lb(0, 0); .block; object-fit: contain; .h(182);}
        .contents { .pl(270); .z(1);
          &.narrow-image { .pl(210); }
        }
      }
    }
  }
}

</style>
