export default {

  lol: [{
    subDetail: 'LCK 프로, 인플루언서, 아마추어가 모인 <br/>자선 기부 대회',
    name: '2021 롤 도네이션 컵',
    arenaUrl: '/b/lol_donation',
    imgUrl: '/img/pages/end/LOL/loldocup.png',
    slide: true,
    game: 'donation',
  }, {
    subDetail: '전국에서 가장 LoL 잘하는 대학교는?',
    name: '대학리그: 배틀 아카데미아',
    arenaUrl: '/b/lol_academia',
    imgUrl: '/img/pages/end/LOL/BattleAcademia.png',
    slide: true,
    game: 'academia',
  }],
  val: [{
    subDetail: '아이언부터 레디언트까지,<br/>샷발을 자랑하고 싶다면 누구나!',
    name: '전국 샷VAL 자랑',
    arenaUrl: '/b/val_shotval',
    imgUrl: '/img/pages/end/VAL/VALORANT.png',
    slide: false,
    game: 'shotval',
  }],
  wr: [{
    subDetail: '한국 대표 선발전을 위한<br/> 와일드 리프트 첫 아마추어 대회',
    name: '2021 와리와리 대난투',
    arenaUrl: '/b/wr_wariwari',
    imgUrl: '/img/pages/end/WR/wariwari.png',
    slide: false,
    game: 'wariwari',
  }],
  tft: [{
    subDetail: '5주 동안 펼쳐진 레전드 컵을 향한 도전',
    name: 'TFT WEEKLY CUP',
    arenaUrl: '/b/tft_weeklycup',
    imgUrl: '/img/pages/end/TFT/TFT.png',
    slide: false,
    game: 'tftWeeklycup',
  }],

};
