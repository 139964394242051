<template>
  <div arena-card-timer>
    <slot :joinState="statusMap.joinState" :remainsToNextState="statusMap.remainsToNextState" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import { remainDayHms } from '@shared/utils/timeUtils';

export default {
  name: 'ArenaCardTimer',
  props: {
    /** @type {ArenaInfo} */
    info: Specific,
  },
  computed: {
    /** @type {ArenaState} */
    state() {
      return this.info?.state;
    },
    /** @type {ArenaSubState} */
    subState() {
      return this.info?.substate;
    },
    /** @type {number} */
    checkinStart() {
      return this.info?.schedule?.checkinStart;
    },
    checkinEnd() {
      return this.info?.schedule?.checkinEnd;
    },
    applyEnd() {
      return this.info?.schedule?.applyEnd;
    },
    start() {
      return this.info?.schedule?.start;
    },
    /** @type {{joinState: string, remainsToNextState: string}} */
    statusMap() {
      const map = {
        'PRE&null': { joinState: 'pre', remainsToNextState: this._remainDayHms(this.applyEnd) },
        'PLAYOFF&READY': this.checkinStart ? { joinState: 'checkInStart', remainsToNextState: this._remainDayHms(this.checkinStart) } : { joinState: 'arenaStart', remainsToNextState: this._remainDayHms(this.start) },
        'PLAYOFF&CHECKIN_START': { joinState: 'checkInEnd', remainsToNextState: this._remainDayHms(this.checkinEnd) },
        'PLAYOFF&CHECKIN_END': { joinState: 'arenaStart', remainsToNextState: this._remainDayHms(this.start) },
        'PLAYOFF&ONGOING': { joinState: 'arenaOngoing', remainsToNextState: '' },
        'END&null': { joinState: 'arenaEnd', remainsToNextState: '' },
      };
      return map[`${this.state}&${this.subState}`] ?? { joinState: '', remainsToNextState: '' };
    },
  },
  methods: {
    _remainDayHms(date) {
      const result = remainDayHms(this.$store, date);
      if (result?.day) return `D-${result.day + 1}`;
      return result?.hms ?? '00:00:00';
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[arena-card-timer] {}
</style>
